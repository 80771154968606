<!--
 * @Description: 评价生产商
 * @Author: zhang zhen
 * @Date: 2023-06-19 09:31:14
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-06-19 09:59:34
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/appraiseSupplier.vue
-->
<template>
  <DrawerView :visible="visible" :width="1120" title="评价" @confirm="handleSubmit" @cancel="handleCancel">
    <a-radio-group v-model="isOpen" :options="options"/>
  </DrawerView>
</template>

<script>
  // 待商榷
  import UploadDrag from '@/components/plugins/uploadDrag'
  import { postAction } from '@/api/manage'
  import DrawerView from '@/components/plugins/drawerView.vue'
  export default {
    name: "appraiseSupplier",
    components: {
      DrawerView,
      UploadDrag
    },
    data() {
      return {
        visible: false,
        options: [
          {
            label: '公开',
            value: '1'
          },
          {
            label: '匿名',
            value: '0'
          }
        ],
        isOpen: '0'
      }
    },
    methods: {
      handleOpen() {
        this.visible = true
      },
      handleSubmit() {

      },
      handleCancel() {
        this.visible = false
      }
    },
  }
</script>

<style lang="less" scoped>

::v-deep .ant-radio-wrapper {
  color: #131212;
}

::v-deep .ant-radio-checked .ant-radio-inner {
  border-width: 5px;
  &::after {
    display: none;
  }
}
</style>
