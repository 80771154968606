<!--
 * @Description: 订单管理
 * @Author: zhang zhen
 * @Date: 2023-02-15 16:18:10
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-29 12:32:03
 * @FilePath: /page-sass/src/views/requirement/orderManagement/index.vue
-->
<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input
          v-model="queryParams.orderNo"
          placeholder="请输入订单编号、订单名称或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px"
        />
        <div class="option-area">
          <a-button type="primary" @click="handleSearch" style="width: 81px">查询</a-button>
          <a-button @click="handleReload" style="width: 81px">重置</a-button>
        </div>
      </div>
      <a-form-model
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        style="margin-top: 8px"
        :colon="false"
      >
        <a-form-model-item label="需求类型">
          <checkboxSearch v-model="queryParams.purchaseTypes" dictCode="1043" />
        </a-form-model-item>
        <a-form-model-item label="包装材料">
          <checkboxSearch v-model="queryParams.wrapTypes" dictCode="1507" />
        </a-form-model-item>
        <a-form-model-item label="订单状态">
          <checkboxSearch v-model="orderStatus" :options="statusOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="创建时间">
          <radioGroup
            v-model="CreateTimeScoped"
            :options="mainOptions"
            :needLoad="false"
            :needAll="false"
            @change="handleChangeRadio($event, 'CreateTimeScoped')"
          >
            <a-radio :value="-1">
              <a-range-picker
                v-model="CreateTimeScopedRange"
                value-format="YYYY-MM-DD HH:mm:ss"
                separator="⇀"
                showTime
                @change="handleChangeDate($event, 'CreateTimeScoped')"
                style="width: 310px; height: 26px"
              >
                <span class="pickerDict">
                  <span v-if="CreateTimeScopedRange.length"
                  >{{ CreateTimeScopedRange[0] }} ~ {{ CreateTimeScopedRange[1] }}</span
                  >
                  <span class="placeholder" v-else>请选择</span>
                  <a-icon type="caret-down" style="margin-left: 8px; color: #999"></a-icon>
                </span>
              </a-range-picker>
            </a-radio>
          </radioGroup>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-info-box">
      <div class="option-area">
        <a-button type="link" @click="handleExport">
          <img src="/export.png" alt="" class="icon" />
          <span>导出数据</span>
        </a-button>
      </div>
      <a-table
        rowKey="orderNo"
        :columns="columns"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
      >
        <span class="row_title" slot="orderNo" slot-scope="text, record">
          <span class="orderName">
            {{ text }}
          </span>
          <img src="/new.png" alt="" class="newIcon" v-if="record.newFlag" />
        </span>
        <template slot="wrapTypesDictName" slot-scope="text, record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ text }}</span>
            </template>
            <span>{{ text | ellipsis(6) }}</span>
          </a-tooltip>
        </template>
        <template slot="status" slot-scope="text, record">
          <span class="table-status-btn" :class="statusDictStyle[text + '']">
            {{ (text && statusDictList[text + '']) || '' }}
          </span>
        </template>
        <template slot="createTime" slot-scope="text, record">
          {{ dayjs(record.createTime).format('YYYY-MM-DD HH:mm:ss') }}
        </template>
        <template slot="operation" slot-scope="text, record">
          <template v-if="record.status == 1 && isPurchaser">
            <a @click="handleStartSign(record)"><a-icon type="upload" /> 上传协议</a>
            <a-divider type="vertical" />
          </template>
          <template v-if="record.status == 1 && isSupplier">
            <!-- <a style="color: #999">等待采购商上传协议</a>
            <a-divider type="vertical" /> -->
          </template>
          <!-- <template v-if="record.newContact && record.status != 0">
            <a @click="handleOPenContractUploader(record)">上传合同</a>
            <a-divider type="vertical" />
          </template>
          <template v-if="record.status == 0">
            <a @click="handleOPenContractUploader(record)">上传合同</a>
            <a-divider type="vertical" />
          </template> -->
          <!-- status = 2 待签署时   orderContractNo 为空 两边显示发起合同调用 订单合同发起接口 -->
          <template v-if="record.status == 2 && !record.orderContractNo">
            <a @click="handleOPenContractUploader(record)">
              <a-icon type="upload" />
              上传合同</a>
            <a-divider type="vertical" />
          </template>
          <!-- 当orderContractNo不为空时， 采购商 -->
          <template v-if="record.status == 2 && record.orderContractNo && isPurchaser">
            <!-- 采购商未签署 -->
            <template v-if="record.purchaseSignFlag != 1">
              <a @click="handleOPenContractUploader(record)">
                <a-icon type="upload" />
                {{ record.contract.signType == 1 ? '签署' : '上传合同'
                }}</a>
              <a-divider type="vertical" />
            </template>
            <!-- 采购商已经签署， 生产商未签署 -->
            <template v-else-if="record.purchaseSignFlag == 1 && record.supplierSignFlag != 1">
              <a style="color: #999">等待生产商签署</a>
              <a-divider type="vertical" />
            </template>
          </template>
          <!-- 当orderContractNo不为空时， 生产商 -->
          <template v-if="record.status == 2 && record.orderContractNo && isSupplier">
            <!--  -->
            <template v-if="record.supplierSignFlag != 1 && record.contract.signType == 1">
              <a @click="handleOPenContractUploader(record)">
                <a-icon type="highlight" />
                签署</a>
              <a-divider type="vertical" />
            </template>
            <template v-else-if="record.supplierSignFlag == 1 && record.purchaseSignFlag != 1">
              <!-- <a style="color: #999">等待采购商签署</a>
              <a-divider type="vertical" /> -->
            </template>
          </template>
          <!-- TODO判断条件 -->
          <!-- <a>合同已上传</a> -->
          <!-- 上传付款单的时候不能是生产商，且必须是已经签署了 -->
          <!-- 定金退款申请 -->
          <!-- <template v-if="record.status == 0">
            <a @click="handleOPenContractUploader(record)">定金退款申请</a>
            <a-divider type="vertical" />
          </template> -->
          <template v-else-if="record.status == '3' && isPurchaser">
            <a @click="handleOpenPayOrder(record.orderNo)">
              <a-icon type="upload" />
              上传预付款单</a>
            <a-divider type="vertical" />
          </template>
          <!-- 上传尾款单 -->
          <template v-else-if="record.status == 4 && isSupplier">
            <a @click="handleOpenEntryProduct(record.orderNo)">
              <a-icon type="enter" />
              进入生产</a>
            <a-divider type="vertical" />
          </template>
          <!-- status == 5 生产商显示 上传发货单按钮  -->
          <template v-else-if="record.status == 5 && isSupplier">
            <a @click="handleOpenTransport(record)">
              <a-icon type="upload" />
              上传发货单</a>
            <a-divider type="vertical" />
          </template>
          <!-- status == 6 时 采购商 显示 上传收货单按钮 -->
          <template v-else-if="record.status == 6 && isPurchaser">
            <a @click="handleOpenUploadShipped(record)">
              <a-icon type="upload" />
              上传收货单</a>
            <a-divider type="vertical" />
          </template>
          <!-- status == 7 采购商显示 上传付款单按钮 -->
          <template v-else-if="record.status == 7 && isPurchaser">
            <a @click="handleOpenPayedOrder(record.orderNo)">
              <a-icon type="upload" />
              上传付款单</a>
            <a-divider type="vertical" />
          </template>
          <!-- status==8 时  采购商判断 purchaseEvaluationFlag  0：未评价 ，1 已评价 生产商判断 supplierEvaluationFlag   0：未评价 ，1 已评价  -->
          <template
            v-if="
              record.status == 8 &&
              ((isPurchaser && record.purchaseEvaluationFlag == 0) ||
                (isSupplier && record.supplierEvaluationFlag == 0))
            "
          >
            <a @click="handleJumpAppraise(record)">
              <a-icon type="pushpin" />
              评价</a>
            <a-divider type="vertical" />
          </template>
          <a @click="handleSwitchTab(record)">
            <a-icon type="eye" />
            详情</a>
          <!-- status = 8 || status = 9  显示翻单  -->
          <template v-if="(record.status == 9 || record.status == 8) && isPurchaser">
            <a-divider type="vertical" />
            <a @click="handleEmitOrder(record)">
              <a-icon type="copy" />
              翻单</a>
          </template>
        </template>
      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <!-- 上传合同 -->
    <UploadContract ref="UploadContract" :orderNo="orderNo" :tradeIdentity="curBusiness.roleType"
                    v-if="curBusiness.roleType" />
    <!-- 预付款单 -->
    <UploadPaymentInfo ref="UploadPaymentInfo" :orderNo="orderNo" />
    <!-- 发货单 -->
    <UploadTransportOrder ref="UploadTransportOrder" :orderNo="orderNo" @openUpload="handleReUploadFile" />
    <!-- 发货单上传 -->
    <UploadTransportFile ref="UploadTransportFileRef" :orderNo="orderNo" />
    <!-- 设计发货 -->
    <UploadTransportIsTemplateFile ref="UploadTransportIsTemplateFileRef" :orderNo="orderNo" />
    <!-- 收货单 -->
    <UploadShippedFile ref="UploadShippedFile" :orderNo="orderNo" />
    <!-- 非设计收获 -->
    <uploadShippedFileImportant ref="uploadShippedFileImportant" :orderNo="orderNo" />
    <!-- 已收货 - 上传付款单 -->
    <UploadPayedFile ref="UploadPayedFile" :orderNo="orderNo" />
    <!-- 修改订单 -->
    <EditOrder ref="EditOrder" :orderNo="orderNo" />
    <!-- 进入生产 -->
    <a-modal
      title="温馨提示"
      :visible="visible"
      centered
      :confirm-loading="confirmLoading"
      @ok="handleEntryProduct"
      @cancel="handleCancelProduct"
    >
      <div class="info-card">
        <img src="/pass.png" alt="" />
        <span>当前订单已进入生产~</span>
      </div>
    </a-modal>
    <!-- 采购商评价生产商 -->
    <AppraiseSupplier ref="AppraiseSupplier" :orderNo="orderNo" />
    <!-- 翻单 -->
    <reNewOrder ref="reNewOrderRef" />
    <!-- 发起签单 -->
    <StartSign ref="StartSignRef" @ok="handleLoadData" />
    <!-- 确定签署方式 -->
    <reOrderSign ref="reOrderSignRef" @ok="handleLoadData" @changeType="handleJumpNewModal" />
    <!-- 线下签署 -->
    <underlineSign ref="underlineSignRef" />
  </div>
</template>

<script>
import InterestedCard from '@/components/tools/interestedCard.vue'
import OrderItem from './modules/orderItem.vue'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters, mapActions } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import UploadContract from './modules/uploadContract.vue'
import UploadPaymentInfo from './modules/uploadPaymentInfo.vue'
import UploadTransportOrder from './modules/uploadTransportOrder.vue'
import UploadShippedFile from './modules/uploadShippedFile.vue'
import UploadPayedFile from './modules/UploadPayedFile.vue'
import { postAction, downFiles, getAction } from '@/api/manage'
import AppraiseSupplier from './modules/appraiseSupplier.vue'
import EditOrder from './modules/editOrder.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import uploadShippedFileImportant from './modules/uploadShippedFileImportant.vue'
import { getCurBusiness, getDateRange } from '@/util/utils'
import UploadTransportFile from './modules/UploadTransportFile.vue'
import UploadTransportIsTemplateFile from './modules/UploadTransportIsTemplateFile.vue'
import reNewOrder from './modules/reNewOrder.vue'
import StartSign from './modules/startSign.vue'
import reOrderSign from './modules/reOrderSign.vue'
import underlineSign from './modules/underlineSign'
import dayjs from 'dayjs'

export default {
  name: 'orderManagement',
  mixins: [ListMixin],
  components: {
    checkboxSearch,
    InterestedCard,
    OrderItem,
    ZPagination,
    EmptyArea,
    UploadContract,
    UploadPaymentInfo,
    UploadTransportOrder,
    UploadShippedFile,
    UploadPayedFile,
    AppraiseSupplier,
    EditOrder,
    radioGroup,
    uploadShippedFileImportant,
    UploadTransportFile,
    UploadTransportIsTemplateFile,
    reNewOrder,
    StartSign,
    reOrderSign,
    underlineSign
  },
  provide() {
    return {
      handleReloadData: this.handleLoadData
    }
  },
  data() {
    return {
      curBusiness: {},
      isSupplier:false,
      isPurchaser:false,
      disableMixinCreated: true,
      selectedRowKeys: [],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      showChangeOrder: false,
      visible: false,
      confirmLoading: false,
      rangerDate: [],
      queryParams: {
        status: [],
        orderNo: null
      },
      url: {
        list: '/order/pageList'
      },
      CreateTimeScoped: '3',
      orderStatus: '',
      CreateTimeScopedRange: [],
      statusOptions: [
        {
          dictKey: 'INIT',
          dictValue: '待生效'
        },
        {
          dictKey: 'WAIT_SIGN',
          dictValue: '待签署'
        },
        {
          dictKey: 'PREPAID',
          dictValue: '待预付'
        },
        {
          dictKey: 'PRODUCING',
          dictValue: '生产中'
        },
        {
          dictKey: 'SHIPPED',
          dictValue: '已发货'
        },
        {
          dictKey: 'RECEIVED',
          dictValue: '已收货'
        },
        {
          dictKey: 'PAID',
          dictValue: '已付款'
        },
        {
          dictKey: 'FINISHED',
          dictValue: '已完结'
        },
        {
          dictKey: 'CANCELED',
          dictValue: '已取消'
        },
        {
          dictKey: 'CLOSE',
          dictValue: '已关闭'
        }
      ],
      mainOptions: [
        {
          dictKey: '0',
          dictValue: '当日'
        },
        {
          dictKey: '1',
          dictValue: '近7日'
        },
        {
          dictKey: '2',
          dictValue: '近30天'
        },
        {
          dictKey: '3',
          dictValue: '近90天'
        },
        {
          dictKey: '4',
          dictValue: '近半年'
        },
        {
          dictKey: '5',
          dictValue: '近一年'
        }
      ],
      columns: [
        // wrapTypesDictName和purchaseTypeDictName
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          key: 'orderNo',
          width: 236,
          scopedSlots: { customRender: 'orderNo' }
        },
        {
          title: '订单名称',
          dataIndex: 'orderTitle',
          key: 'orderTitle',
          ellipsis: true,
          width: 200
        },
        {
          title: '需求类型',
          dataIndex: 'purchaseTypeDictName',
          key: 'purchaseTypeDictName',
          width: 120
        },
        {
          title: '包装材料',
          dataIndex: 'wrapTypesDictName',
          key: 'wrapTypesDictName',
          scopedSlots: { customRender: 'wrapTypesDictName' },
          width: 160
        },
        {
          title: '订单金额/元',
          dataIndex: 'orderAmount',
          key: 'orderAmount',
          width: 150
        }, {
          title: '实付金额/元',
          dataIndex: 'payAmount',
          key: 'payAmount',
          width: 150
        }, {
          title: '待付金额/元',
          dataIndex: 'pendingAmount',
          key: 'pendingAmount',
          width: 150
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
          width: 140
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 180
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 240,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      sorter: {
        userId: this.setUserInfo().businessId || this.setUserInfo().userId
      },
      requestType: 'post',
      orderNo: null,
      createOrderInfo: null,
      statusDictList: {
        1: '待生效',
        2: '待签署',
        3: '待预付',
        4: '待生产',
        5: '生产中',
        6: '已发货',
        7: '已收货',
        8: '已付款',
        9: '已完结',
        '-1': '已取消',
        '-2': '订单已关闭'
      },
      statusDictStyle: {
        1: 'status_2',
        2: 'status_2',
        3: 'status_1',
        4: 'status_1',
        5: 'status_6',
        6: 'status_7',
        7: 'status_8',
        8: 'status_9',
        9: 'status_10',
        '-1': 'status_10',
        '-2': 'status_10'
      }
    }
  },
  created() {
    this.curBusiness = getCurBusiness()
    console.log('this.curBusiness'+getCurBusiness())
    this.isPurchaser = false
    this.isSupplier = false
    if (this.curBusiness.roleType == '2') {
      this.isPurchaser = true
    }
    else{
      this.isSupplier = true
    }
    const { query } = this.$route
    query.orderNo && (this.queryParams.orderNo = query.orderNo)
    this.handleLoadData(1)
  },
  methods: {
    dayjs,
    ...mapGetters(['setUserInfo']),
    ...mapActions(['changeInfoCount']),
    handleCleanCount() {
      this.changeInfoCount({
        orderCount: 0
      })
    },
    handleStartSign(item) {
      this.$refs.reOrderSignRef.handleOpen(item)
    },
    handleJumpNewModal({ type, record }) {
      if (type == 1) { // 在线
        this.$refs.UploadContract.handleOpen(record)
      } else { // 线下
        this.$refs.underlineSignRef.handleOpen(record)
      }
    },
    handleReload() {
      this.queryParams = {
        status: [],
        orderNo: null
      }
      this.CreateTimeScopedRange = []
      this.orderStatus = ''
      this.CreateTimeScoped = '3'
      this.handleLoadData(1)
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = []
      }
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      const { wrapTypes, orderNo, purchaseTypes } = this.queryParams
      let pageParams = { ...this.pageNation },
        params = { orderNo },
        wrapTypesList = [],
        purchaseTypesList = []
      delete pageParams.total
      if (this.orderStatus) {
        params.status = this.orderStatus.split(',')
      }
      if (purchaseTypes) {
        purchaseTypesList = purchaseTypes.split(',')
      }
      if (wrapTypes) {
        wrapTypesList = wrapTypes.split(',')
      }
      if (this.CreateTimeScoped != '' && this.CreateTimeScoped != -1) {
        if (this.CreateTimeScoped == '6') {
          let lastYear = new Date().getFullYear() - 1
          params = { ...params, startDate: `${lastYear}-01-01 00:00:00`, endDate: `${lastYear}-12-31 23:59:59` }
        } else {
          const [startDate, endDate] = getDateRange(this.CreateTimeScoped)
          params = { ...params, startDate, endDate }
        }
      } else if (this.CreateTimeScoped != -1 && this.CreateTimeScopedRange.length) {
        const [startDate, endDate] = this.CreateTimeScopedRange
        params = { ...params, startDate, endDate }
      }
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : postAction
      api(this.url.list, {
        pagesSize: pageSize,
        purchaseTypes: purchaseTypesList,
        wrapTypes: wrapTypesList,
        pageNum,
        ...params,
        ...this.sorter
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data

            this.dataSource = list
            if(list.length>0){
              list.map((item, index) => {
                item.pendingAmount=item.orderAmount-item.payAmount
              })
            }
            this.pageNation['total'] = total
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    handleSearch() {
      this.handleLoadData(1)
    },
    /* 开启合同上传 */
    handleOPenContractUploader(item) {
      const { contract, orderNo, supplierSignFlag, purchaseSignFlag } = item
      this.orderNo = orderNo
      this.handleJumpNewModal({
        type: contract.signType,
        record: { ...contract, supplierSignFlag, purchaseSignFlag }
      })
    },
    /* 打开上传付款单 */
    handleOpenPayOrder(orderNo) {
      this.orderNo = orderNo
      this.$refs.UploadPaymentInfo.handleShowModal(orderNo)
    },
    /* 进入生产 */
    handleOpenEntryProduct(orderNo) {
      this.orderNo = orderNo
      this.visible = true
      this.confirmLoading = false
    },
    /* 进入生产的提示 */
    handleEntryProduct() {
      getAction('/order/produce', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, message } = res
        if (success) {
          // // this.$message.success(message)
          this.visible = false
          this.handleLoadData()
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 评价 */
    handleJumpAppraise({ orderNo }) {
      this.$router.push(
        this.isPurchaser
          ? `/orderManagement/appraiseForUser?orderNo=${orderNo}`
          : `/orderManagement/appraisePurchaser?orderNo=${orderNo}`
      )
    },
    /* 取消发货提示 */
    handleCancelProduct() {
      this.visible = false
      this.confirmLoading = false
    },
    /* 导出 */
    handleExport() {
      let params = {}
      if (this.orderStatus) {
        params.status = this.orderStatus.split(',')
      }
      if (this.CreateTimeScoped != '' && this.CreateTimeScoped != -1) {
        if (this.CreateTimeScoped == '6') {
          let lastYear = new Date().getFullYear() - 1
          params = {
            ...params,
            startDate: `${lastYear}-01-01 00:00:00`,
            endDate: `${lastYear}-12-31 23:59:59`,
            ...this.sorter
          }
        } else {
          const [startDate, endDate] = getDateRange(this.CreateTimeScoped)
          params = { ...params, startDate, endDate, ...this.sorter }
        }
      } else if (this.CreateTimeScoped != -1 && this.CreateTimeScopedRange.length) {
        const [startDate, endDate] = this.CreateTimeScopedRange
        params = { ...params, startDate, endDate, ...this.sorter }
      } else {
        params = {
          ...this.queryParams,
          ...this.sorter
        }
      }
      downFiles('/order/export/query', params).then((data) => {
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(new Blob([data], { type: 'application/vnd.ms-excel' }), fileName + '.zip')
        } else {
          let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', window.TemplateName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) // 下载完成移除元素
          window.URL.revokeObjectURL(url) // 释放掉blob对象
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    /* 开启发货单 */
    handleOpenTransport(item) {
      this.orderNo = item.orderNo
      if (item.purchaseType == '1043001') { // 设计
        this.$refs.UploadTransportIsTemplateFileRef.handleOpen({ ...item })
      } else {
        this.$refs.UploadTransportOrder.handleEdit(item)
      }
    },
    handleReUploadFile(e) {
      this.$refs.UploadTransportFileRef.handleOpen(e)
    },
    /* 收货单上传 */
    handleOpenUploadShipped(item) {
      this.orderNo = item.orderNo
      if (item.purchaseType == '1043001') { // 设计
        this.$refs.UploadShippedFile.handleOpen({ ...item })
      } else {
        this.$refs.uploadShippedFileImportant.handleOpen({ ...item })
      }
    },
    /* 上传预付款 */
    handleOpenPayedOrder(orderNo) {
      this.orderNo = orderNo
      this.$refs.UploadPayedFile.handleOpen(orderNo)
    },
    handleSwitchTab(record) {
      const { orderTitle, orderNo, purchaseId, status } = record
      this.$router.push(
        this.isSupplier
          ? `/orderManagement/orderDetails?orderTitle=${orderTitle}&orderNo=${orderNo}&purchaseId=${purchaseId}&&hasEdit=${false}`
          : `/orderManagement/orderDetailsForUser?orderTitle=${orderTitle}&orderNo=${orderNo}&purchaseId=${purchaseId}&hasEdit=${
            status == 0
          }`
      )
    },
    handleEmitOrder(item) {
      this.$refs.reNewOrderRef.handleOpen(item)
    },
    /* 订单修改 */
    handleEditOrder(item) {
      this.$refs.EditOrder.handleInitData(item)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #efefef;

    th {
      border: none;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type) > td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #f4f5f7 !important;
  border-bottom: none;
  font-size: 14px;
  font-weight: 600;
  color: #131212;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;

    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

.option-area {
  // margin-bottom: 15px;
  text-align: right;

  .ant-btn-link {
    color: #ff6026;
    font-weight: 400;
  }

  .icon {
    width: 20px;
    margin-right: 2px;
    margin-top: -2px;
  }
}

@import '~@/styles/list.less';

img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.info-card {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.card-info-box {
  ::v-deep .ant-form-item-label {
    width: 67px !important;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

.card-info-box {
  ::v-deep .ant-badge-dot {
    right: -6px;
  }
}
</style>
