<!--
 * @Description: 上传收货单 - 非设计的
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:26:43
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-06-13 17:33:18
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/UploadTransportFile.vue
-->
<template>
  <DrawerView :visible="visible" :width="640" :title="'上传发货单'" show-slot-title @confirm="handleSubmit" @cancel="handleCancel">
    <a-form-model :colon="false">
      <a-form-model-item>
        <div class="uploadCover">
          <UploadDrag v-model="uploadFiles" @change="handleChangeFile" :orderNo="orderNo" :number="1" />
        </div>
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65);">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import UploadDrag from './uploadTransportTemplate.vue'
import { postAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
export default {
  name: 'UploadTransportFile',
  components: {
    UploadDrag,
    DrawerView
  },
  inject: ['handleReloadData'],
  // props: {
  //   orderNo: {
  //     type: String,
  //     default: ''
  //   }
  // },
  data() {
    return {
      visible: false,
      loading: false,
      uploadFiles: [],
      orderNo: null,
      transportsVo: [],
      maxNum: 1,
    }
  },
  methods: {
    handleOpen({ orderNo, transportsVo, purchaseType }) {
      // this.maxNum = purchaseType == '1043001' ? 10 : 1
      // console.log(this.maxNum)
      this.visible = true
      this.orderNo = orderNo
      this.transportsVo = transportsVo || [];
    },
    handleChangeFile(e) {
      this.uploadFiles = e
    },
    handleSubmit() {
      if (!this.uploadFiles.length) return this.$message.warning('请上传发货单据文件')
      this.loading = true
      let invoiceFiles = this.uploadFiles ? this.uploadFiles.map(i => i.fileUrl) : []

      let dataList = this.transportsVo.map(i => {
        return {
          ...i,
          invoiceFiles,
          orderNo: this.orderNo,
          freight: 0
        }
      })

      postAction('/order/transport', dataList).then(res => {
        const { success, message } = res
        this.loading = false
        if (success) {
          postAction('/order/shipped', {
            orderNo: this.orderNo
          }).then(res => {
            const { success, message } = res
            if (success) {
              // this.$message.success(message)
              this.handleReloadData(1) // 刷新主列表
              this.handleCancel()
            } else {
              this.$message.warning(message)
            }
          })
        } else {
          this.$message.warning(message)
        }
      }).catch(e => {
        this.loading = false
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
      this.transportsVo = []
      this.maxNum = 1
    }
  }
}
</script>


<style lang="less" scoped>
.uploadCover {
  width: 100%;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .endTitle {
    width: 424px;
    margin-top: -15px;
    text-align: center;
    line-height: 20px;
    // .flexLayout();
    font-size: 12px;
    // justify-content: center;
    color: #8C8C8C;
    margin-top: 8px;

    span.text {
      color: #FF6026;
    }
  }
}

.ant-form-item-label>label {
  color: #000;
}
</style>
