<!--
 * @Description: 填写收货单
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:00:59
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-23 18:00:23
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadTransportOrder.vue
-->
<template>
  <DrawerView :visible="visible" :width="990" :title="'填写发货单'" show-slot-title @confirm="handleOk" @cancel="handleCancel">
    <a-tabs v-model="activePart" tab-position="left" class="step_form">
      <a-tab-pane v-for="(item, index) in transportsVo" :key="index">
        <template #tab>
          <img src="/to_input.png" alt="" class="to_input" v-if="!item.shipmentStatus" />
          <img src="/input_finished.png" alt="" class="to_input" v-else />
          <span class="label-span" style="margin-right: 15px">发货单{{ index + 1 }}</span>
        </template>
        <a-form-model
          class="info-form"
          :model="item"
          :label-col="{
            span: 6,
          }"
          :wrapper-col="{
            span: 18,
          }"
          :ref="`infoForm_${index}`"
          :colon="false"
          :rules="rules"
        >
          <h2 class="page-title">
            <span class="title">发货信息</span>
          </h2>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="发货方式" prop="shipmentType">
                <j-dictSelect v-model="item.shipmentType" style="width: 100%" placeholder="请选择" dictCode="1018" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="运单号" prop="shipmentNo" v-if="item.shipmentType != '1018002'">
                <a-input placeholder="请输入" v-model="item.shipmentNo" style="width: 100%"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
            <a-row>
            <a-col :span="12">
              <a-form-model-item label="发货时间" prop="shipmentTime">
                <JDate
                  placeholder="请选择"
                  style="width: 100%"
                  v-model="item.shipmentTime"
                  :passDate="lastDate"
                  :removePast="true"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <h2 class="page-title not-first">
            <span class="title">收货信息</span>
          </h2>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="期望收货时间">
                <j-date placeholder="请选择" v-model="item.expectedDeliveryDate" style="width: 100%" disabled />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="收货人">
                <a-input placeholder="请输入" v-model="item.receiveName" style="width: 100%" disabled></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="手机号">
                <a-input placeholder="请输入" v-model="item.receivePhone" style="width: 100%" disabled></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item
                label="收货地址"
                :label-col="{
                  span: 3,
                }"
                :wrapper-col="{
                  span: 21,
                }"
              >
                <a-input
                  placeholder="请输入"
                  class="lastBtn"
                  v-model="item.receiveDetailAddress"
                  style="width: 100%"
                  :maxLength="20"
                  disabled
                >
                  <div class="NumberInfo" slot="suffix">
                    {{ (item.receiveDetailAddress && item.receiveDetailAddress.length) || 0 }}/20
                  </div>
                </a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <h2 class="page-title not-first">
            <span class="title">发货数量</span>
          </h2>
          <a-table class="table-box" :columns="columns" :pagination="false" :data-source="item.shipmentItems" bordered>
            <template slot="shipmentQuantity" slot-scope="text, record, index">
              <a-input-number
                id="inputNumber"
                v-model="record.shipmentQuantity"
                :min="0"
                :max="record.skuPlanQuantity"
                placeholder="请填写实际收货数量"
                :precision="0"
              />
            </template>
          </a-table>
          <h2 class="page-title">
            <span class="title">发货单据</span>
          </h2>
          <div class="uploadCover">
            <UploadDrag
              v-model="item.uploadFiles"
              @change="handleChangeFile($event, item)"
              :orderNo="item.id"
              :number="1"
            />
          </div>
        </a-form-model>
      </a-tab-pane>
    </a-tabs>

    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
      <a-button @click="handleOk" type="primary" :loading="confirmLoading">发货</a-button>
    </template>
  </DrawerView>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import JDate from '@/components/easyComponents/JDate.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import UploadDrag from './uploadTransportTemplate.vue'

import dayjs from 'dayjs'
export default {
  name: 'uploadTransportOrder',
  components: {
    UploadDrag,
    JDate,
    DrawerView,
    JDictSelect,
    splitLine,
  },
  inject: ['handleReloadData'],
  data() {
    return {
      visible: false,
      confirmLoading: false,
      activePart: 0,
      form: {
        detailAddress: '',
      },
      rules: {
        deliveryAddress: { required: true, message: '请选择收货地址' },
        detailAddress: { required: true, message: '请选择详细地址' },
        shipmentType: { required: true, message: '请选择发货方式' },
        shipmentNo: { required: true, message: '请输入运单号' },
        insuredAmount: { required: true, message: '请输入保价金' },
        freight: { required: true, message: '请输入运费' },
        shipmentTime: { required: true, message: '请选择发货时间' },
      },
      regionOptions: [],
      regionList: [],
      deliveryCityList: [],
      deliveryDistrictList: [],
      uploadFiles: [],
      lastDate: null,
      orderNo: null,
      newFill2: false,
      isAllUploadALl: false,
      transportsVo: [],
      purchaseType: null,
      columns: [
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 173,
        },
        {
          title: '散件SKU名称',
          dataIndex: 'skuName',
          width: 163,
        },
        {
          title: '计划所需数量(个)',
          dataIndex: 'skuPlanQuantity',
          width: 183,
        },
        {
          title: '实际发货数量(个)',
          dataIndex: 'shipmentQuantity',
          width: 183,
          scopedSlots: { customRender: 'shipmentQuantity' },
        },
      ],
      dataSource: [
        {
          wrapTypeDictName: '瓦楞纸',
          typName: '制造',
          planNum: 100,
          // sendNum: 100,
          receiveNum: '',
        },
        {
          wrapTypeDictName: '非瓦楞纸',
          typName: '制造',
          planNum: 90,
          // sendNum: 90,
          receiveNum: '',
        },
      ],
      shipmenUser: null,
    }
  },
  methods: {
    handleEdit({ createTime, orderNo, supplierBusinessName, purchaseType, id }) {
      this.purchaseType = purchaseType || ''
      this.lastDate = (createTime && dayjs(new Date(createTime)).format('YYYY-MM-DD')) || null
      this.visible = true
      this.orderNo = orderNo
      this.shipmenUser = supplierBusinessName
      this.handleLoadOrder()
    },
    handleChangeFile(e, item) {
      item.uploadFiles = e.splice(-1, 1)
      item.shipmentFile = item.uploadFiles[0]['url']
    },
    handleLoadOrder() {
      getAction('/order/query/delivery', {
        orderNo: this.orderNo,
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.transportsVo = data || []
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleOk() {
      this.$refs[`infoForm_${this.activePart}`][0].validate((valid) => {
        if (valid) {
          const { shipmentFile, shipmentNo, shipmentTime, shipmentType, shipmentItems, orderNo, id } = this.transportsVo[this.activePart]
          // 验证当前发货数量是不是空的， 必须填
          if (!shipmentItems.every((item) => item.shipmentQuantity)) {
            return this.$message.warning('请填写实际发货数量')
          }
          if (!shipmentFile) {
            return this.$message.warning('请上传发货单据')
          }
          let formData = {
            shipmentFile,
            shipmentNo,
            shipmentTime,
            shipmentType,
            orderNo,
            id,
            shipmenUser: this.shipmenUser,
            shipmentItems: shipmentItems.map((item) => ({
              id: item.id,
              receiveQuantity: '',
              shipmentQuantity: item.shipmentQuantity,
            })),
          }
          this.confirmLoading = true
          postAction('/order/shipment', formData)
            .then((res) => {
              const { success, message } = res
              this.confirmLoading = false
              if (success) {
                this.handleReloadData(1) // 刷新主列表
                this.transportsVo[this.activePart]['shipmentStatus'] = 2;
                let flag = this.transportsVo.every(i => i.shipmentStatus == 2);
                if (flag) {
                  this.handleCancel()
                }
              } else {
                this.$message.warning(message)
              }
            })
            .catch((e) => {
              this.confirmLoading = false
            })
        }
      })
    },
    handleCancel(needNew = false) {
      this.lastDate = null
      this.visible = false
      this.confirmLoading = false
      this.uploadFiles = []
      this.activePart = 0
      this.transportsVo = []
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/form.less';

.uploadCover {
  width: 560px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}

.NumberInfo {
  color: rgba(0, 0, 0, 0.45);
}

.ant-form-item-label > label {
  color: #000;
}

.info-form {
  // ::v-deep .ant-form-item-label {
  //   width: 96px !important;
  // }
}

.step_form {
  ::v-deep .ant-tabs-content {
    padding-left: 25px;
  }
}

// ::v-deep .ant-tabs-ink-bar-animated {
//   height: 24px!important;
// }
::v-deep .ant-tabs-nav .ant-tabs-tab {
  padding: 1px 0;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

::v-deep .ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.green_btn:not([disabled]) {
  background-color: #05c29c;
  border-color: #05c29c;
}

span.label-span {
  display: inline-block;
  width: 90px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

img.to_input {
  width: 52px;
  margin-right: 15px;
}

::v-deep .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.lastBtn {
  ::v-deep .ant-input {
    padding-right: 56px;
  }
}

.page-title {
  &:not(:first-of-type) {
    margin-top: 28px;
  }
  &:first-of-type {
    margin-top: -8px;
  }
  &.not-first {
    margin-top: 0;
  }
  font-size: 15px;
  color: #605f5f;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #FF6026;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: #ff6026;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;

    img.icon {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.table-box {
  min-height: auto !important;
  ::v-deep .ant-table-tbody tr:not(:last-of-type) > td {
    border-right: 1px solid #e8e9eb !important;
  }

  ::v-deep .ant-table-thead > tr > th {
    background: #f4f5f7;
    border-right: 1px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;
    padding: 8px 10px;
    font-size: 14px;
  }
  ::v-deep .ant-table-column-title {
    font-size: 14px;
  }

  ::v-deep .ant-table-tbody tr > td {
    border-bottom: 1px solid #e8e9eb !important;
    padding: 5px 10px;
  }

  ::v-deep .ant-table-wrapper {
    min-height: auto !important;
    border-bottom: none;
  }

  ::v-deep .ant-input-number {
    width: 100%;
    height: 28px;
    line-height: 28px;
    .ant-input-number-input {
      height: 28px;
      font-size: 14px;
      font-weight: 350;
      &::placeholder {
        color: #8c8c8c;
        /* 设置占位符文本的颜色 */
      }
    }
  }
  ::v-deep .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25)!important;
  }
  ::v-deep .ant-input {
    border: none;
    outline: 0;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  ::v-deep .ant-input-number-handler-wrap {
    display: none;
  }
}

.uploadCover {
  width: 100%;
  border: 1px solid #efefef;
}
</style>
