<!--
 * @Description: 订单列表 - 协议签署
 * @Author: zhang zhen
 * @Date: 2023-05-30 09:29:17
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-20 17:25:50
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadContract.vue
-->
<template>
  <DrawerView :visible="visible" :width="800" :title="'协议签署'" @cancel="handleCancel" :show-slot-title="true">
    <!-- 用户显示区域 -->
    <div class="user-area">
      <div class="user-area-item">
        <img src="~@/assets/order/userIcon_1.png" alt="" class="avatar" />
        <div class="info-box">
          <span class="name">采购商：{{ basicInfo.purchaseBusinessName }}</span>
          <div class="signType">
            <span class="type"
              >签署方式：线上签署</span
            >
          </div>
        </div>
        <img
          src="~@/assets/order/tag_1.png"
          alt=""
          class="tag"
          v-if="basicInfo.purchaseSignFlag && basicInfo.purchaseSignFlag == '1'"
        />
        <img src="~@/assets/order/tag_2.png" alt="" class="tag" v-else />
      </div>
      <div class="user-area-item">
        <img src="~@/assets/order/userIcon_2.png" alt="" class="avatar" />
        <div class="info-box">
          <span class="name">生产商：{{ basicInfo.supplierBusinessName }}</span>
          <div class="signType">
            <span class="type">签署方式：线上签署</span>
            <!-- <a>（查看合同）</a> -->
          </div>
        </div>
        <img
          src="~@/assets/order/tag_1.png"
          alt=""
          class="tag"
          v-if="basicInfo.supplierSignFlag && basicInfo.supplierSignFlag == '1'"
        />
        <img src="~@/assets/order/tag_2.png" alt="" class="tag" v-else />
      </div>
    </div>
    <!-- <a-tabs :activeKey="activeKey" @change="handleChangeTab">
      <a-tab-pane key="1" tab="在线签署" force-render> -->
        <!-- <emptyBox /> -->
        <div class="online-sign">
          <div class="card_1">
            <div class="title">手机扫码签署</div>
            <div class="sub-title">支持微信、支付宝扫码</div>
            <div class="code">
              <qrcode :value="codeUrl" :options="{ width: 160 }"></qrcode>
            </div>
          </div>
          <div class="card_2">
            <div class="title">电脑PC签署</div>
            <div class="sub-title">电脑浏览器、网页签署</div>
            <a-button type="primary" class="action-btn" @click="handleOpenSign">打开签署链接</a-button>
          </div>
        </div>
    <!-- <a-form-model :colon="false">
      <a-form-model-item label="上传合同" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" label-align="left" required>

      </a-form-model-item>
    </a-form-model>
    <a-card :title="tradeIdentity == 0 ? '采购商上传合同' : '生产商上传合同'">
      <FileListView ref="FileListViewRef" />
    </a-card> -->
    <!-- <a-modal title="温馨提示" :visible="showDig1" centered @ok="handleOk(2)" @cancel="showDig2">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>您已在线签署合同，如再线下签署将废弃之前合同，确定重新签署吗？</span>
      </div>
    </a-modal> -->
    <a-modal title="温馨提示" :visible="showDig2" centered @ok="handlePassChange" @cancel="showDig2 = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span
          >对方已在线签署/线下签署合同，为保障合同具有法律效力，请选择同一签署方式。如确定选择在线签署/线下签署，请及时联系对方更换签署方式。</span
        >
      </div>
    </a-modal>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
      <!-- <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button> -->
    </template>
  </DrawerView>
</template>

<script>
import contractUploader from './contractUploader.vue'
import { postAction, getAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import FileListView from './children/fileListView.vue'
import emptyBox from './emptyBox.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'uploadContract',
  components: {
    contractUploader,
    DrawerView,
    FileListView,
    emptyBox,
  },
  inject: ['handleReloadData'],
  props: {
    orderNo: {
      type: String,
      default: '',
    },
    tradeIdentity: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      showDig1: false,
      showDig2: false,
      changeTab: '1',
      visible: false,
      loading: false,
      isUpload: false,
      uploadFiles: [],
      basicInfo: {},
      activeKey: '1',
      codeUrl: '异常',
      contractInfo: {},
      looperTimer: null,
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleOpen(item) {
      const { bizOrderNo, purchaseBusinessName, supplierBusinessName, supplierSignFlag, purchaseSignFlag, purchaseSignUrl, supplierSignUrl } = item
      this.basicInfo = {
        purchaseBusinessName,
        supplierBusinessName,
        supplierSignFlag,
        purchaseSignFlag,
        orderNo: bizOrderNo,
      }
      this.codeUrl = this.setUserInfo().tradeIdentity == 0 ? supplierSignUrl : purchaseSignUrl
      this.visible = true
    },
    handleLoadStatus() {
      getAction('/order/queryByOrderNo', {
        orderNo: this.basicInfo.orderNo,
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { purchaseSignFlag, supplierSignFlag } = data
          let flag = this.setUserInfo().tradeIdentity == 0 ? supplierSignFlag : purchaseSignFlag
          if (flag == 1) {
              this.looperTimer && clearInterval(this.looperTimer)
              this.handleReloadData(1)
              this.handleCancel();
            }
        }
      })
    },
    handlePassChange() {
      this.showDig2 = false;
      this.activeKey = this.changeTab
      this.handleChangeSignType()
    },
    handleChangeSignType() {
      postAction('/v1/contract/order/initiate', {
        signType: Number(this.activeKey),
        orderNo: this.basicInfo.orderNo,
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          if (this.activeKey == '1') {
            const { purchaseSignFile, supplierSignFile, status } = data
            this.codeUrl = this.setUserInfo().tradeIdentity == 0 ? supplierSignFile : purchaseSignFile
          }
          this.contractInfo = data
        } else {
          this.$message.error(message)
        }
      })
    },
    handleOpenSign() {
      // 当前tab打开
      window.open(this.codeUrl)
    },
    handleViewFile(file) {
      this.$nextTick((_) => {
        this.$refs.FileListViewRef.handleGetFileList([file])
      })
    },
    handleSetFileUrl(url) {
      this.$nextTick((_) => {
        this.$refs.UploadDragRef.initFileList([
          {
            url,
            srcFileName: url.split('/images/')['1'],
          },
        ])
      })
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.activeKey = '1'
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-tabs-tab {
  padding: 12px 0 20px;
  font-size: 16px;
}

::v-deep .ant-alert-info {
  border: none;
  background: rgba(255, 125, 47, 0.08);
  border-radius: 4px;
  height: 40px;

  &.success-alert {
    background: #e6faf3;
  }

  &.error-alert {
    background: #ee42611a;
  }

  .info-icon {
    width: 17px;
    margin-top: -9px;
  }

  .ant-alert-message {
    color: #131212;
  }
}

.uploadCover {
  margin-top: 25px;
  width: 752px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}

.ant-form-item-label > label {
  color: #000;
}

.user-area {
  display: flex;
  align-items: center;
  grid-gap: 0 15px;
  margin-top: 23px;
  margin-bottom: 15px;
  &-item {
    width: 364px;
    height: 84px;
    background: #f4f5f7;
    border: 1px solid #e8e9eb;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    box-sizing: border-box;
    .avatar {
      width: 52px;
      margin-right: 8px;
    }
    .info-box {
      flex: 1 0 0;
      min-width: 0;
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        color: #131212;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }
      .signType {
        width: 100%;
        display: flex;
        align-items: center;
        //styleName: 常规/正文1 body_md_regular;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #5e5e66;
      }
    }
    img.tag {
      width: 52px;
    }
  }
}
::v-deep .ant-tabs-top-bar {
  margin-bottom: 0;
}
.online-sign {
  display: flex;
  align-items: center;
  height: calc(100vh - 300px);
  // min-height: 316px;
  background: #f7f8fa;
  grid-gap: 0 8px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
  }
  .card_1 {
    width: 336px;
    height: 100%;
    background: #fff;
  }
  .card_2 {
    flex: 1 0 0;
    min-width: 0;
    height: 100%;
    background: #fff;
  }
  .title {
    color: #222226;
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    margin-bottom: 4px;
  }
  .sub-title {
    color: #999;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    margin-bottom: 16px;
  }
  .action-btn {
    width: 132px;
    height: 38px;
  }
  .code {
    width: 160px;
    height: 160px;
  }
}

.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}
</style>
